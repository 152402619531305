/**
 * 极验sdk支持77种语言，这里和我们界面语言做一个映射，兜底是英语en;
 */
export const getGeetestLang = () => {
    const map = {
        'zh-CN': 'zh-cn',
        'zh-TW': 'zh-tw',
        'ja': 'ja',
        'ko': 'ko',
        'th': 'th',
    };
    return map[KLOOK.getLocale()] || 'en';
};
/**
 *
 * @param src
 */
export const loadScript = (src, cb) => {
    const head = document.getElementsByTagName('head') && document.getElementsByTagName('head')[0] || document.documentElement;
    const script = document.createElement('script');
    script.src = src;
    let loadFlag = false;
    script.onload = script.onreadystatechange = function () {
        if (!loadFlag &&
            (!this.readyState ||
                this.readyState === 'loaded' ||
                this.readyState === 'complete')) {
            loadFlag = true;
            if (typeof cb === 'function') {
                cb();
            }
        }
        script.onload = null;
        if (head && script.parentNode) {
            head.removeChild(script);
        }
    };
    head.insertBefore(script, head.firstChild);
};
export const promisePost = (url, data) => {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.addEventListener('readystatechange', () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                try {
                    resolve(JSON.parse(xhr.response));
                }
                catch (error) {
                    resolve({ success: false, error: { message: 'json parse error' } });
                }
            }
        });
        const postData = Object.keys(data)
            .map((key) => `${key}=${encodeURIComponent(data[key])}`)
            .join('&');
        xhr.send(postData);
    });
};
