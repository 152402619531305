import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
let App = class App extends Vue {
    // 获取当前语言当前页面的title
    getRouteTitle() {
        for (const item of this.$route.matched.reverse()) {
            if (item.meta.title) {
                return this.$t(item.meta.title);
            }
        }
        return;
    }
    updated() {
        // 修改页面title
        document.title = (this.getRouteTitle() || this.$t('global.counter')) + ' - ' + this.$t('global.klook_travel');
    }
};
tslib_1.__decorate([
    namespace('antLocale').State('locale')
], App.prototype, "locale", void 0);
App = tslib_1.__decorate([
    Component
], App);
export default App;
