import * as tslib_1 from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let KlookPageTitle = class KlookPageTitle extends Vue {
};
tslib_1.__decorate([
    Prop(String)
], KlookPageTitle.prototype, "title", void 0);
KlookPageTitle = tslib_1.__decorate([
    Component
], KlookPageTitle);
export default KlookPageTitle;
