import Vue from 'vue';
const requireComponent = require.context('.', true, /\.vue$/);
requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    // 获取组件的驼峰命名
    const componentName = fileName
        .slice(fileName.lastIndexOf('/') + 1) // 获取最后的路径
        .replace(/^\.\/_/, '') // 去掉文件路径
        .replace(/\.\w+$/, '') // 去掉后缀名
        .split('-') // 分割分割符
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1)) // 首字母大写
        .join(''); // 拼接
    // 全局注册组件
    Vue.component(`klook${componentName}`, componentConfig.default || componentConfig);
});
