const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName:"layout-login" */ '../views/_modules/layout/common/layout-login.vue'),
        redirect: { name: 'login' },
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName:"login" */ '../views/login/index.vue'),
            },
            {
                path: 'forget_password',
                name: 'forgetPassword',
                component: () => import(/* webpackChunkName:"forget-password" */ '../views/forget-password/index.vue'),
            },
            {
                path: 'set_password',
                name: 'setPassword',
                component: () => import(/* webpackChunkName:"set-password" */ '../views/set-password/index.vue'),
            },
        ],
    },
    {
        path: '/verify_token',
        name: 'verifyToken',
        component: () => import(/* webpackChunkName:"verify-token" */ '../views/verify-token/index.vue'),
    },
];
export default routes;
