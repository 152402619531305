const MD5 = require('md5.js');
import { LANGUAGE_LIST, LANGUAGE_LOCALE_MAPPING } from '@/common/js/constant/index.ts';
// 回到页面顶部
const scrollTop = () => {
    const content = document.querySelector('#klook-conetent');
    if (content) {
        content.scrollTop = 0;
    }
};
// 获取当前语言
const getLocale = () => {
    const urlLocale = window.location.pathname.split('/')[1];
    return LANGUAGE_LIST.some((item) => item.value === urlLocale)
        ? urlLocale
        : 'en';
};
/**
 * 深拷贝数据
 * @param target 数据源
 */
const clone = (target) => {
    return JSON.parse(JSON.stringify(target));
};
/**
 * 校验邮箱地址
 * @param {string} email 邮箱地址
 * @return {boolean}
 */
const isEmail = (email) => {
    const reg = /^[a-zA-Z0-9_-]+(\.([a-zA-Z0-9_-])+)*@[a-zA-Z0-9_-]+[.][a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*$/;
    return reg.test(email);
};
/**
 * 检查是否是数字
 * @param {string} str 检查的字符串或数字
 * @return {boolean}
 */
const isNumber = (str) => {
    const reg = /^[\d]+$/;
    return reg.test(str.toString());
};
const isValidPwd = (str) => {
    // 必须包含数字字母且8-20位
    const reg = /(?!^[0-9]+$)(?!^[A-Za-z~!@#$%^&*]+$)^[0-9A-Za-z~!@#$%^&*]{8,20}$/;
    return reg.test(str);
};
/**
 * 价格添加千分位
 * @param {number | string} price 价格
 * @return {string} 格式化后的价格
 */
const formatPriceThousands = (price) => {
    price = (price || 0).toString();
    let result;
    if (price.indexOf('.') < 0) {
        result = price.replace(/(?=(?!(\b))(\d{3})+$)/g, ',');
    }
    else {
        const priceArr = price.split('.');
        result = (priceArr[0]).toString().replace(/(?=(?!(\b))(\d{3})+$)/g, ',') + '.' + priceArr[1];
    }
    return result;
};
/**
 * 过滤空格
 * @param {string} str 需要过滤的字符串
 * @return {string} 过滤后的字符串
 */
const filterSpace = (str) => {
    return str.replace(/\s+/g, '');
};
/**
 * 过滤emoji表情
 * @param {string} str 需要过滤的字符串
 * @return {string} 过滤后的字符串
 */
const filterEmoji = (str) => {
    const reg = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
    return str.replace(reg, '');
};
// localeStorage快捷方法
const LS = {
    set(key, value) {
        window.localStorage.setItem(key, value);
    },
    get(key) {
        return window.localStorage.getItem(key);
    },
    remove(key) {
        window.localStorage.removeItem(key);
    },
};
/**
 * MD5加密
 * @param {String} str 要加密的字符串
 */
const md5 = (str) => {
    str = str.toString();
    return new MD5().update(str).digest('hex');
};
/**
 * 下载文件
 * @param {String} url 要下载文件的url
 */
const downloadFile = (url) => {
    const locale = LANGUAGE_LOCALE_MAPPING[getLocale()];
    window.open(url + '&lang=' + locale);
};
// 是否移动端
const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
export { scrollTop, getLocale, isEmail, isNumber, isValidPwd, formatPriceThousands, filterSpace, filterEmoji, clone, md5, downloadFile, LS, isMobile, };
