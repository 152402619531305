import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import store from '@/store/index.ts';
Vue.use(VueI18n);
// 站点多语言与ant多语言文件名称映射
const antLocaleMapping = {
    'en': 'en_GB',
    'ja': 'ja_JP',
    'ko': 'ko_KR',
    'th': 'th_TH',
    'zh-CN': 'zh_CN',
    'zh-TW': 'zh_TW',
};
// 已加载的语言
const loadedLanguages = [];
const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {},
});
function _setI18nLanguage(lang) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
}
function loadLanguageAsync(lang) {
    // ant库多语言
    import(`ant-design-vue/lib/locale-provider/${antLocaleMapping[lang]}.js`).then((msg) => {
        store.commit('antLocale/updateLocale', msg.default);
    });
    if (!loadedLanguages.includes(lang)) {
        return import(`@/locales/${lang}.json`).then((msg) => {
            i18n.setLocaleMessage(lang, msg.default);
            loadedLanguages.push(lang);
            return _setI18nLanguage(lang);
        });
    }
    return Promise.resolve(_setI18nLanguage(lang));
}
export { i18n, loadLanguageAsync, };
