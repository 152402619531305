import store from '@/store/index.ts';
const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName:"desktop_layout" */ '../views/_modules/layout/desktop/layout.vue'),
        redirect: { name: 'home' },
        children: [
            {
                path: 'home',
                name: 'home',
                meta: {
                    needAuth: true,
                    title: 'nav.home',
                },
                component: () => import(/* webpackChunkName:"desktop_home" */ '../views/home/desktop/index.vue'),
            },
            {
                path: 'redemption',
                name: 'redemption',
                meta: {
                    needAuth: true,
                    title: 'nav.redemptions',
                },
                component: () => import(/* webpackChunkName:"desktop_redemption" */ '../views/redemption/desktop/index.vue'),
            },
            {
                path: 'manage_account',
                name: 'manageAccount',
                meta: {
                    needAuth: true,
                    title: 'nav.manage_account',
                    permission: () => store.state.user.user_type === 'main',
                },
                component: () => import(/* webpackChunkName:"desktop_manage-account" */ '../views/manage-account/desktop/index.vue'),
            },
            {
                path: 'settings',
                name: 'settings',
                meta: {
                    needAuth: true,
                    title: 'nav.settings',
                },
                component: () => import(/* webpackChunkName:"desktop_settings" */ '../views/settings/desktop/index.vue'),
            },
        ],
    },
];
export default routes;
