import { promisePost, getGeetestLang, loadScript } from './utils';
import './gt.js';
const INIT_CAPTCHA_API = '/v1/merchantcountersrv/captcha/captcha_service/captcha_init';
const reCAPTCHA_KEY = '6LdVIZ8UAAAAALTPJ7j1VbcjQ7jN6fm2Lr5o98ko';
/**
 *
 * @param {*} action 用于区分使用场景；比如登陆、注册
 */
const initCaptcha = (action) => {
    return promisePost(INIT_CAPTCHA_API, {
        action,
        data: '',
    }).then((resp) => {
        if (!resp || !resp.success) {
            return Promise.reject(resp && resp.error);
        }
        const { captcha_type, need_captcha, captcha_seq_no, geetest_host } = resp.result;
        if (need_captcha === false) {
            return { captcha_seq_no, need_captcha };
        }
        if (captcha_type === 'geetest') {
            const lang = getGeetestLang();
            const options = Object.assign({
                offline: false,
                new_captcha: true,
            }, resp.result.geetest, {
                lang,
                captcha_seq_no,
                product: 'bind',
                pure: 1,
                api_server: geetest_host,
            });
            return new Promise((resolve) => {
                const initGeetest = window.initGeetest;
                initGeetest(options, (captchaObj) => {
                    captchaObj.onReady(() => {
                        resolve({
                            captchaObj,
                            captcha_seq_no,
                            type: 'geetest',
                            geetest_id: options.gt,
                            geetest_offline: !!options.offline,
                        });
                    });
                });
            });
        }
        if (captcha_type === 'google_v3') {
            loadScript(`https://www.google.com/recaptcha/api.js?render=${reCAPTCHA_KEY}`);
        }
        return { action, captcha_seq_no, type: captcha_type };
    });
};
const getCaptcha = (captcha) => {
    return new Promise((resolve, reject) => {
        if (!captcha) {
            resolve(false);
            return;
        }
        if (captcha.need_captcha === false) {
            resolve({ captcha_seq_no: captcha.captcha_seq_no });
            return;
        }
        if (captcha.type === 'geetest') {
            const { captchaObj, captcha_seq_no, geetest_id, geetest_offline, } = captcha;
            if (!captchaObj || !captchaObj.onReady) {
                return Promise.reject({
                    message: 'Something was wrong with geetest init',
                });
            }
            captchaObj
                .onSuccess(() => {
                const { geetest_challenge, geetest_validate, geetest_seccode, } = captchaObj.getValidate();
                resolve({
                    geetest_challenge,
                    geetest_validate,
                    geetest_seccode,
                    geetest_id,
                    captcha_seq_no,
                    geetest_offline,
                    captcha_type: 3,
                });
            })
                .onError((error) => {
                reject({
                    message: (error && error.user_error) || 'Something was wrong with geetest',
                });
            })
                .onClose(() => {
                reject();
            });
            captchaObj.verify();
            return;
        }
        if (captcha.type === 'google_v3') {
            const { action, captcha_seq_no } = captcha;
            const fn = (cb = resolve) => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(reCAPTCHA_KEY, { action }).then((token) => {
                        cb({ captcha_seq_no, captcha_type: 4, recaptcha_v3: token });
                    });
                });
            };
            if (!window.grecaptcha) {
                window.onloadCallback = fn;
                loadScript(`https://www.google.com/recaptcha/api.js?render=${reCAPTCHA_KEY}&onload=onloadCallback`);
                return;
            }
            fn();
            return;
        }
        resolve(captcha);
    });
};
/**
 * 验证
 * @param {*} action
 * @param {*} that vue里面的this对象，默认赋值this.captchaSettings 为 initCaptcha(action)的返回值
 */
const simpleCaptchaVerify = (action, that) => {
    if (!that || !action) {
        return Promise.resolve();
    }
    return getCaptcha(that.captchaSettings).then((result) => {
        if (result === false) {
            // 表示第一次initCaptcha初始化失败
            return initCaptcha(action).then((captcha) => {
                that.captchaSettings = captcha;
                return getCaptcha(captcha);
            });
        }
        return result;
    });
};
/**
 * 初始化
 * @param {*} action
 * @param {*} that
 * @param {*} isReset 强制重新初始化
 */
const simpleCaptchaInit = (action, that, isReset) => {
    if (!action || !that) {
        return;
    }
    if (isReset === true) {
        that.captchaSettings = null;
        return;
    }
    initCaptcha(action)
        .then((result) => {
        that.captchaSettings = result;
    })
        .catch(() => { });
};
/**
 * 错误处理，清空captchaSettings的值
 * @param {*} error
 * @param {*} that
 */
const simpleCaptchaErrHandler = (error, that) => {
    if (!error || !that) {
        return;
    }
    const { captchaObj } = that.captchaSettings || { captchaObj: {} };
    if (captchaObj && typeof captchaObj.reset === 'function') {
        captchaObj.reset();
    }
    if (+error.code === 10129) {
        // 表示captcha_seq_no失效，需要重新调用initCaptcha获取
        that.captchaSettings = '';
    }
};
export { initCaptcha, getCaptcha, simpleCaptchaVerify, simpleCaptchaInit, simpleCaptchaErrHandler, };
