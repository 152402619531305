import Axios from 'axios';
import router from '@/router/index';
import { Modal, message } from 'ant-design-vue';
import Cookie from 'js-cookie';
const ajax = {};
ajax.install = (Vue) => {
    const axiosInstance = Axios.create({
        timeout: 1000 * 10,
    });
    // 请求拦截
    axiosInstance.interceptors.request.use((config) => {
        config.headers['x-platform'] = KLOOK.isMobile ? 'mobile' : 'desktop';
        return config;
    });
    // 响应拦截
    axiosInstance.interceptors.response.use((response) => {
        const res = response.data;
        if (!res.success) {
            const error = res.error;
            const errorCode = error && error.code;
            // 需要登录
            if ([400, 4001, 11001].includes(+errorCode)) {
                Modal.error({
                    content: error.message || 'Error',
                    onOk() {
                        Cookie.remove('isLogin');
                        router.push({ name: 'login' });
                    },
                });
                return Promise.reject(''); // error传空，不用小弹窗提示
            }
            else {
                return Promise.reject(error.message);
            }
        }
        return res.result;
    }, (error) => {
        return Promise.reject(error);
    });
    Vue.prototype.$ajax = {
        get(url, params, isShowCommonErrorMessage = true) {
            return new Promise((resolve, reject) => {
                axiosInstance.get(url, { params }).then((res) => {
                    resolve(res);
                }).catch((err) => {
                    if (err) {
                        if (isShowCommonErrorMessage) {
                            message.error(err.toString());
                        }
                        reject(err);
                    }
                });
            });
        },
        post(url, params, isShowCommonErrorMessage = true) {
            return new Promise((resolve, reject) => {
                axiosInstance.post(url, params).then((res) => {
                    resolve(res);
                }).catch((err) => {
                    if (err) {
                        if (isShowCommonErrorMessage) {
                            message.error(err.toString());
                        }
                        reject(err);
                    }
                });
            });
        },
    };
};
export default ajax;
