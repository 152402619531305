const updateInnerTable = {};
updateInnerTable.install = (Vue) => {
    Vue.prototype.$updateInnerTable = () => {
        // 内嵌表格表单统一高度
        Vue.nextTick(() => {
            const tables = document.querySelectorAll('.ant-table-body'); // 所有ant表格
            tables.forEach((table) => {
                // 所有表格行
                const trs = table.querySelectorAll('.ant-table-tbody > .ant-table-row');
                const trsHeightMap = {};
                trs.forEach((tr, trIndex) => {
                    const innerTables = tr.querySelectorAll('.inner-table');
                    // 有一个以上合并行才计算
                    if (innerTables.length > 1) {
                        innerTables.forEach((innerTable) => {
                            const innerTrs = innerTable.querySelectorAll('tr');
                            // 内嵌表格有多列才需要计算
                            if (innerTrs.length > 1) {
                                innerTrs.forEach((innerTr, innerTrIndex) => {
                                    const key = `${trIndex},${innerTrIndex}`;
                                    const height = innerTr.offsetHeight;
                                    if (!trsHeightMap[key] || trsHeightMap[key] < height) {
                                        trsHeightMap[key] = height;
                                    }
                                });
                            }
                        });
                    }
                });
                // 修改内嵌表格行高度
                for (const key of Object.keys(trsHeightMap)) {
                    const index = key.split(',');
                    const innerTables = trs[index[0]].querySelectorAll('.inner-table');
                    innerTables.forEach((innerTable) => {
                        const tr = innerTable.querySelectorAll('tr')[index[1]];
                        tr.style.height = trsHeightMap[key] + 'px';
                    });
                }
            });
        });
    };
};
export default updateInnerTable;
