export default [
    {
        name: 'home',
        title: 'nav.home',
        icon: 'mobile-home',
        platform: ['desktop', 'mobile'],
    }, {
        name: 'redemption',
        title: 'nav.redemptions',
        icon: 'mobile-redemption',
        platform: ['desktop', 'mobile'],
    }, {
        name: 'userCenter',
        title: 'nav.user',
        icon: 'mobile-user',
        platform: ['mobile'],
    },
];
