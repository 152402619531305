import Vue from 'vue';
import Router from 'vue-router';
import { loadLanguageAsync } from '@/i18n';
import Cookie from 'js-cookie';
import routesCommon from './common';
import routesDesktop from './desktop';
import routesMobile from './mobile';
import store from '@/store/index.ts';
import { URLS } from '@/common/js/constant/index.ts';
import { Modal } from 'ant-design-vue';
Vue.use(Router);
const locale = KLOOK.getLocale();
const routes = KLOOK.isMobile ? routesMobile : routesDesktop;
const router = new Router({
    mode: 'history',
    routes: [...routes, ...routesCommon],
    base: locale !== 'en' ? locale : '',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    },
});
router.beforeEach(async (to, from, next) => {
    const isLogin = Cookie.get('isLogin') === '1';
    Modal.destroyAll();
    // 已登录返回登录页面则跳转到首页
    if (to.name === 'login' && isLogin) {
        router.push({ name: 'home' });
        return;
    }
    // 需要验证登录
    if (to.meta.needAuth) {
        // 如未登录则跳转登录页
        if (!isLogin) {
            router.push({ name: 'login' });
            return;
        }
        // 第一次访问则获取用户信息
        if (!store.state.user.user_guid) {
            await _getUserInfo();
        }
    }
    // 如果权限未通过则跳转首页
    if (to.meta.permission && !to.meta.permission()) {
        router.push({ name: 'home' });
    }
    loadLanguageAsync(locale).then(() => next());
    next();
});
// 获取并设置用户信息
async function _getUserInfo() {
    const res = await router.app.$ajax.get(URLS.getUserInfo);
    if (res) {
        // 更新到vuex
        store.commit('user/updateUserInfo', res);
    }
    else {
        Cookie.remove('isLogin');
        router.push({ name: 'login' });
    }
}
export default router;
