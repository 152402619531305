import * as tslib_1 from "tslib";
import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
let User = class User extends VuexModule {
    constructor() {
        super(...arguments);
        this.isLoadingPhoneCode = false;
        this.phoneCountryCode = [];
    }
    setPhoneCountryCode(phoneCountry) {
        this.phoneCountryCode = phoneCountry || this.phoneCountryCode;
    }
    setLoadingPhoneCode(isLoading) {
        this.isLoadingPhoneCode = isLoading;
    }
};
tslib_1.__decorate([
    Mutation
], User.prototype, "setPhoneCountryCode", null);
tslib_1.__decorate([
    Mutation
], User.prototype, "setLoadingPhoneCode", null);
User = tslib_1.__decorate([
    Module({ namespaced: true, name: 'phoneCountry' })
], User);
export default User;
