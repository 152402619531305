const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName:"mobile_layout" */ '../views/_modules/layout/mobile/layout.vue'),
        redirect: { name: 'home' },
        children: [
            {
                path: 'home',
                name: 'home',
                meta: {
                    needAuth: true,
                    title: 'nav.home',
                },
                component: () => import(/* webpackChunkName:"mobile_home" */ '../views/home/mobile/index.vue'),
            },
            {
                path: 'redemption',
                name: 'redemption',
                meta: {
                    needAuth: true,
                    title: 'nav.redemptions',
                },
                component: () => import(/* webpackChunkName:"mobile_redemption" */ '../views/redemption/mobile/index.vue'),
            },
            {
                path: 'user',
                name: 'userCenter',
                meta: {
                    needAuth: true,
                    title: 'nav.user',
                },
                component: () => import(/* webpackChunkName:"mobile_user" */ '../views/user-center/mobile/index.vue'),
            },
        ],
    },
];
export default routes;
