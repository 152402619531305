import Vue from 'vue';
import Vuex from 'vuex';
import antLocale from './modules/ant-locale';
import user from './modules/user';
import countryPhone from './modules/country-phone';
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        antLocale,
        user,
        countryPhone,
    },
});
