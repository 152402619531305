import * as tslib_1 from "tslib";
import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
let User = class User extends VuexModule {
    constructor() {
        super(...arguments);
        this.locale = {};
    }
    updateLocale(locale) {
        this.locale = locale || this.locale;
    }
};
tslib_1.__decorate([
    Mutation
], User.prototype, "updateLocale", null);
User = tslib_1.__decorate([
    Module({ namespaced: true, name: 'antLocale' })
], User);
export default User;
