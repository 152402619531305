import Vue from 'vue';
import App from './App.vue';
import { Ajax, SuccessTips, UpdateInnerTable } from './plugins/index';
import router from './router/index';
import store from './store/index';
import { i18n } from './i18n';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import './common/css/global.less'; // 自定义全局样式
import './components/index.ts'; // 自定义的全局组件
Vue.use(Antd);
Vue.use(Ajax);
Vue.use(SuccessTips);
Vue.use(UpdateInnerTable);
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
// 处理SVG
const svgFiles = require.context('./assets/imgs/svg-icon', false, /.svg$/);
svgFiles.keys().map(svgFiles);
