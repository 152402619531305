import * as tslib_1 from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index.ts';
import Cookie from 'js-cookie';
let KlookPhoneInput = class KlookPhoneInput extends Vue {
    constructor() {
        super(...arguments);
        this.phoneCode = '';
        this.phoneNumber = '';
        this.isSelectFocus = false;
        this.allCountryCode = [];
        this.codeList = [];
    }
    get defaultCode() {
        return Cookie.get('localIdd');
    }
    onValueChanged(val) {
        const index = val && val.indexOf('-');
        if (index > -1) {
            this.phoneCode = val.slice(0, index) || this.defaultCode;
            this.phoneNumber = val.slice(index + 1);
        }
        else {
            this.phoneNumber = val;
        }
    }
    onPhoneCodeChanged(val) {
        this.triggerUpdate();
    }
    onPhoneNumberChanged() {
        this.triggerUpdate();
    }
    created() {
        this.getPhoneCountryCode();
    }
    /**
     * 筛选电话code
     * @param {String} input 输入筛选的值
     */
    filterList(value) {
        if (value) {
            this.codeList = this.allCountryCode.filter((item) => {
                return item.country.toLowerCase().indexOf(value.toLowerCase()) >= 0;
            });
        }
        else {
            this.codeList = this.allCountryCode;
        }
        document.querySelector('.klook-phone-code-list').scrollTop = 0;
    }
    // 电话输入过滤非数组
    filterNumber(e) {
        this.phoneNumber = this.phoneNumber.replace(/\D/gi, '');
    }
    /**
     * 国家被选中
     * @param {Object} info 被选中的项
     */
    onCodeSelected(info) {
        this.phoneCode = this.codeList[info.key].code;
    }
    // 触发更新
    triggerUpdate() {
        const phoneNumber = this.phoneNumber && this.phoneNumber.trim();
        const phoneCode = this.phoneCode;
        let value = '';
        if (phoneNumber && phoneCode) {
            value = `${this.phoneCode}-${phoneNumber}`;
        }
        else if (phoneNumber) {
            value = `-${phoneNumber}`;
        }
        else if (phoneCode) {
            value = `${this.phoneCode}-`;
        }
        this.$emit('update:value', value);
    }
    // 触发change事件
    triggerChange() {
        this.triggerUpdate();
        this.$emit('change');
    }
    // 触发change事件
    triggerBlur() {
        this.triggerUpdate();
        this.$emit('blur');
    }
    // 获取国家电话code
    getPhoneCountryCode() {
        // 存一个获取电话区号中的状态到vuex，避免重复请求
        const isLoadingPhoneCode = this.$store.state.countryPhone.isLoadingPhoneCode;
        const countryCode = this.$store.state.countryPhone.phoneCountryCode;
        if (!countryCode.length && !isLoadingPhoneCode) {
            this.$store.commit('countryPhone/setLoadingPhoneCode', true);
            this.$ajax.get(URLS.getPhoneCode).then((res) => {
                this.$store.commit('countryPhone/setPhoneCountryCode', res);
                this.allCountryCode = res;
                this.codeList = this.allCountryCode;
            });
        }
        else {
            this.allCountryCode = countryCode;
            this.codeList = this.allCountryCode;
        }
    }
};
tslib_1.__decorate([
    Prop({ type: String, default: 'default' })
], KlookPhoneInput.prototype, "size", void 0);
tslib_1.__decorate([
    Prop(String)
], KlookPhoneInput.prototype, "value", void 0);
tslib_1.__decorate([
    Prop(Boolean)
], KlookPhoneInput.prototype, "disabled", void 0);
tslib_1.__decorate([
    Watch('value', { immediate: true })
], KlookPhoneInput.prototype, "onValueChanged", null);
tslib_1.__decorate([
    Watch('phoneCode')
], KlookPhoneInput.prototype, "onPhoneCodeChanged", null);
tslib_1.__decorate([
    Watch('phoneNumber')
], KlookPhoneInput.prototype, "onPhoneNumberChanged", null);
KlookPhoneInput = tslib_1.__decorate([
    Component
], KlookPhoneInput);
export default KlookPhoneInput;
